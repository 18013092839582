import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Team Box
import TeamBox from "./team-box";

class AboutUs extends Component {
  state = {
    members: [
      {
        id: 1,
        name: "Frank Johnson",
        image: "assets/images/team/img-1.jpg",
        post: "CEO",
      },
      {
        id: 2,
        name: "Elaine Stclair",
        image: "assets/images/team/img-2.jpg",
        post: "DESIGNER",
      },
      {
        id: 3,
        name: "Wanda Arthur",
        image: "assets/images/team/img-3.jpg",
        post: "DEVELOPER",
      },
      {
        id: 4,
        name: "Joshua Stemple",
        image: "assets/images/team/img-4.jpg",
        post: "MANAGER",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section" id="about">
          <Container>
            {/* <Row> */}
              <Col lg={{ size: 8, offset: 2 }}>
                <div className="about-title mx-auto text-center">
                  <h2 className="font-weight-light">
                    About Iteruti{" "}
                  </h2>
                  <p className="text-muted pt-4">
                    {/* About Iteruti */}
                    Iteruti was born in 2023 as a way pulish web service and app ideas all under one roof. Iteruti is based in London, UK. 
                  </p>
                </div>
              </Col>
            {/* </Row> */}
            {/* <Row className="mt-5"> */}
              {/* Render Team members */}
              {/* {this.state.members.map((member, key) => (
                <TeamBox
                  key={key}
                  name={member.name}
                  image={member.image}
                  post={member.post}
                />
              ))} */}
            {/* </Row> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
