import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="products">
          <Container>
            <Row className="align-items-center">
              <Col lg="5" className="order-2 order-lg-1">
                <div className="features-box mt-5 mt-lg-0">
                  <h3>
                    Octorati
                  </h3>
                  <p className="text-muted web-desc">
                  An email service for those using the UK's Octopus Agile Energy tariff.
                  </p>
                  <p className="text-muted web-desc">
                  Take advantage of import price drops or high export prices! Octorati makes use of Octopus Energy’s official data APIs to give you easy to use information through personalised email notifications. 
                  </p>
                  <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className="">We help you stay aware of the latest rates.</li>
                    <li className="">
                      No additional apps, we're just a simple email subscription.
                    </li>
                    <li className="">Importantly, we are free!</li>
                  </ul>
                  <Link
                    to="https://www.octorati.iteruti.com"
                    className="btn btn-primary mt-4 waves-effect waves-light"
                  >
                    Visit Octorati <i className="mdi mdi-arrow-right"></i>
                  </Link>
                </div>
              </Col>
              <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
                <div className="features-img mx-auto me-lg-0">
                  <img
                    src="assets/images/growth-analytics.svg"
                    alt="macbook"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
